import './App.css';
import FrontPage from './components/FrontPage';
// import ProjectPage from './components/ProjectPage';
// import Header from './components/Header';s

function App() {
  return (
    <div>
        <FrontPage></FrontPage>
        {/* <ProjectPage></ProjectPage> */}
    </div>
  );
}

export default App;
